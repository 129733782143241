import { __rest } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { keys } from 'lodash/fp';
import PropTypes from 'prop-types';
import * as React from 'react';
import { forwardRef } from 'react';
import { trackEvent } from '../../services/Tracking';
export const KINDS_CLASSMAP = {
    DEFAULT: 'button',
    PRIMARY: 'button save',
    PRIMARY_ALTERNATE: 'button done',
    WARNING: 'button warning',
    TRANSPARENT: 'button button--transparent',
    LINK: 'link-style',
    PLAIN: '',
};
export const KINDS = keys(KINDS_CLASSMAP);
const Button = forwardRef((_a, ref) => {
    var { kind = 'DEFAULT', isSmall = false, eventTracking, className } = _a, passedProps = __rest(_a, ["kind", "isSmall", "eventTracking", "className"]);
    const cN = classNames({ small: isSmall }, KINDS_CLASSMAP[kind], className);
    return (_jsx("button", Object.assign({}, passedProps, { className: cN, ref: ref, onClick: e => {
            var _a;
            if (eventTracking) {
                trackEvent(eventTracking);
            }
            (_a = passedProps.onClick) === null || _a === void 0 ? void 0 : _a.call(passedProps, e);
        } })));
});
export default Button;
export const buttonPropTypes = {
    kind: PropTypes.oneOf(KINDS),
    disabled: PropTypes.bool,
    isSmall: PropTypes.bool,
    onClick: PropTypes.func,
    className: PropTypes.string,
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    children: PropTypes.node,
    'aria-label': PropTypes.string,
    'data-intercom-target': PropTypes.string,
};
Button.propTypes = buttonPropTypes;
Button.defaultProps = {
    disabled: false,
    onClick: () => { },
    className: '',
    type: 'button',
    isSmall: false,
};
