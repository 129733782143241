// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PopupAlertStatus-popup_alert-2SVwi {\n  padding: 20px;\n  padding-left: 15px;\n  border-left: 10px solid transparent;\n  border-radius: 5px;\n}\n.PopupAlertStatus-success-20t-c {\n  border-left-color: #2ecd72;\n}\n.PopupAlertStatus-error-3pcBK {\n  border-left-color: #f23037;\n}\n.PopupAlertStatus-info-1t0yt {\n  padding: 20px;\n  border-radius: 5px;\n}\n", ""]);
// Exports
exports.locals = {
	"popup_alert": "PopupAlertStatus-popup_alert-2SVwi",
	"success": "PopupAlertStatus-success-20t-c PopupAlertStatus-popup_alert-2SVwi",
	"error": "PopupAlertStatus-error-3pcBK PopupAlertStatus-popup_alert-2SVwi",
	"info": "PopupAlertStatus-info-1t0yt"
};
module.exports = exports;
